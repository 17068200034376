import React from "react";
import { ucfirst } from "../lib/string-utils";
import Image from "./image/image";
import * as styles from "./role-list.module.scss";

function RoleList({ items, title }) {
  return (
    <div className={styles.root}>
      <h2 className={styles.headline}>{title}</h2>
      <ul className={styles.list}>
        {items.map((item, i) => (
          <li key={`role-${i}`} className={styles.listItem}>
            <div>
              <div className={styles.avatar}>
                {item.person && item.person.image && item.person.image.asset && (
                  <Image
                    asset={item.person.image.asset}
                    width={100}
                    height={100}
                    alt={item.person.image.alt}
                    fit={"crop"}
                  ></Image>
                )}
              </div>
            </div>
            <div>
              <div>
                <strong>{(item.person && item.person.name) || <em>Missing name</em>}</strong>
              </div>
              {item.roles && (
                <div>
                  {item.roles.map((role, idx) => {
                    switch (true) {
                      case idx === 0:
                        return <span key={role}>{ucfirst(role)}</span>;
                      case idx === item.roles.length - 1:
                        return <span key={role}> & {role}</span>;
                      default:
                        return <span key={role}>, {role}</span>;
                    }
                  })}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RoleList;
