import { format, formatDistance, differenceInDays } from "date-fns";
import React from "react";
import { Link } from "gatsby";
import BlockContent from "./block-content";
import RoleList from "./role-list";
import Image from "./image/image";

import * as styles from "./project.module.scss";

function Project(props) {
  const {
    _rawBody,
    title,
    isNorwegian,
    categories,
    mainImage,
    members,
    publishedAt,
    relatedProjects
  } = props;

  return (
    <article className={styles.root}>
      {props.mainImage && mainImage.asset && (
        <>
          <div className={styles.mainImage}>
            {isNorwegian && (
              <div className={styles.badge}>
                <img
                  src="/standard_norges-flagg.png"
                  alt="norwegian badge"
                  style={{ height: "auto", width: "40px" }}
                  width={40}
                ></img>
              </div>
            )}
            <Image asset={mainImage.asset} width={1260} alt={mainImage.alt} fit={"fill"}></Image>
            {mainImage.caption && <p className={styles.caption}>{mainImage.caption}</p>}
          </div>
        </>
      )}
      <div className={styles.grid}>
        <div className={styles.mainContent}>
          {_rawBody && <BlockContent blocks={_rawBody || []} />}
        </div>
        <aside>
          {publishedAt && (
            <div className={styles.publishedAt}>
              {differenceInDays(new Date(publishedAt), new Date()) > 3
                ? formatDistance(new Date(publishedAt), new Date())
                : format(new Date(publishedAt), "MMMM do yyyy")}
            </div>
          )}
          {members && members.length > 0 && (
            <div className={styles.roleList}>
              <RoleList items={members} title="Author" />
            </div>
          )}
          {categories && categories.length > 0 && (
            <div className={styles.categories}>
              <h3 className={styles.categoriesHeadline}>Categories</h3>
              <ul>
                {categories.map((category, i) => (
                  <li key={`category-${i}`}>{category.title}</li>
                ))}
              </ul>
            </div>
          )}
          {relatedProjects && relatedProjects.length > 0 && (
            <div className={styles.relatedProjects}>
              <h3 className={styles.relatedProjectsHeadline}>Related projects</h3>
              <ul>
                {relatedProjects.map((project, i) => (
                  <li key={`project-${i}`}>
                    {project.slug ? (
                      <Link to={`/project/${project.slug.current}`}>{project.title}</Link>
                    ) : (
                      <span>{project.title}</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </aside>
      </div>
    </article>
  );
}

export default Project;
