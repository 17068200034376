// extracted by mini-css-extract-plugin
export var root = "project-module--root--mfGZP";
export var mainImage = "project-module--mainImage--Xq2nj";
export var grid = "project-module--grid---TTM9";
export var mainContent = "project-module--mainContent--S2yvB";
export var publishedAt = "project-module--publishedAt--FLFY7";
export var roleList = "project-module--role-list--1dTQ3";
export var badge = "project-module--badge--UBMxM";
export var caption = "project-module--caption--TYl5b";
export var categories = "project-module--categories--aFrZ1";
export var categoriesHeadline = "project-module--categoriesHeadline--ngdx3";
export var relatedProjects = "project-module--relatedProjects--oB4Wy";
export var relatedProjectsHeadline = "project-module--relatedProjectsHeadline--lynpm";